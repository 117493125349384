.worksSection .row:nth-child(2n) {
    flex-direction: row-reverse;
}
.worksSection .workpageInner {
    background: var(--worksBg);
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0px 0px;
    border-radius: 5px;
    min-height: 350px;
}
.worksSection .workCards::before{
    display: none;
}
.facilitiesSection {
    background: #EC5E1C;
}

.facilitiesSection h2, .facilitiesSection h4{
    color: var(--white);
}

.facilitiesSection .upsCards {
    background: none;
}
.facilitiesSection .upsInner  {
    padding: 0px
}
.facilitiesSection .upsInner h5, .facilitiesSection .upsInner p {
    color: var(--white);
}
.facilitiesSection .upsInner p::before{
    background-color: var(--white);
}
.facilitiesSection .upsId h4.h4 {
    background: #EC5E1C;
    color: var(--white);
    border-color: var(--white);
}
.facilitiesSection .uspCard::before {
    background: var(--white);
    top: 26px;
}
@media screen and (max-width:767px){
    .worksSection .workCards {
        padding-bottom: 20px;
    }
    .facilitiesSection .upsCards {
        margin-top: 30px;
    }
}