.homeBanner{
    background-color: blueviolet;
}

.slider {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--top-bg);
  padding: 40px 0px 80px;
}

.slider .mainBanner{
    width: 100% !important;
  transform:none !important;
  object-fit: cover;
}
.slick-track {
  display: flex;
}
.slick-list {
  overflow: hidden;
}
.slider--inner{
  align-items: center;
}
.slider--item .h1 {
  font-weight: 700;
  line-height: 150%;
  padding-right: 10px;
}
.slider--item h5 {
  font-weight: 300;
  line-height: 180%;
  width: 80%;
}

.slider-button{
  padding:  20px 0;
  display: flex;
}
.slider-button .button-1{
  margin-right: 20px;
}
.slider-button .button-1:hover{
  color: var(--white);
}
.slider-user {
  display: flex;
  align-items: center;
}
.slider-user figure {
  margin: 0px 15px 0px 0px;
}
.user-heading {
  margin: 0px;
  font-family: var(--secondary-font);
  line-height: 100%;
  font-size: 2rem;
  color: var(--secondary-color);
  font-weight: 400;
}
.slider-user h6 {
  margin: 0px;
  line-height: 130%;
  color: var(--secondary-color);
}
.slider .slick-dots {
  margin: 0px;
  padding: 0px;
  justify-content: center;
  display: flex !important;
  position: absolute;
  bottom: 50px;
  width: 100%;
}
.slider .slick-dots li{
  margin: 0px 5px;
}
.slider .slick-dots button {
  font-size: 0px;
  width: 15px;
  height: 15px;
  background: var(--text-color);
  border-radius: 100%;
  border: none;
}
.slider .slick-dots li.slick-active button{
  background-color: var(--secondary-color);
}

@media screen and (max-width:1399px){
  
  .slider--item h5 {
    width: 100%;
    line-height: 150%;
  }
  .slider-button {
    padding: 10px 0 15px;
    display: flex;
  }

}
@media screen and (max-width:1199px){
  .slider--item .h1 {
    line-height: 120%;
  }
}
@media screen and (max-width:991px){
  .slider .mainBanner {

    margin-top: 20px;
} 
}
@media screen and (max-width:768px){
  
}
@media screen and (max-width:767px){
  
}

@media screen and (max-width:576px){
  .slider-button {
    padding: 10px 0 15px;
    display: flex;
    flex-wrap: wrap;
}
.slider-button .button-1 {
  margin-right: 0px;
  margin-bottom: 20px;
}
}
@media screen and (max-width:420px){
  
}