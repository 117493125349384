.header .navbar {
    background-color: var(--top-bg);
    padding: 15px 0px;
}
.header .navbar-nav ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
}
.header .navbar-nav ul li {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    position: relative;
}
.header .navbar-nav ul li .nav-item.dropdown {
    position: static;
}
.header .navbar-nav ul li a{
    text-decoration: none;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 500;
}
.header .navbar-nav ul li a:hover, .header .navbar-nav ul li.active a{
    color: var(--secondary-color);

}
.header .navbar-nav ul li .dropdown-menu a:hover, .header .navbar-nav ul li .dropdown-menu .dropdown-item.active  {
    background-color:var(--primary-color);
    color: var(--white);
}
.header .container {
    align-items: center;
    display: flex;
}
.headerMenu {
    justify-content: flex-end;
    align-items: center;
}
.headerButton {
    display: flex;
    align-items: center;
    padding: 0px;
    margin: 0px;
}
.headerButton li {
    padding: 0px 10px;
}
.headerButton li:last-child {
    padding-right: 0px ;
}
.headerButton li a.button-1:hover {
    color: var(--white);
}
@media screen and (max-width:1399px){
    .header .navbar-nav ul li a {
        font-size: 0.8rem;
    }
    .header .navbar-nav ul li {
      padding: 0px 10px;
    }
    .btnOutline {
        padding: 12px 15px;
    }
  
  }
  @media screen and (max-width:1199px){
    img.logo {
        height:50px;
    }
    .btnfilled {
        padding: 12px 10px;
        font-size: 0.9rem;
    }
    .btnOutline {
        padding: 12px 10px;
        font-size: 0.9rem;
    }
    .header .navbar-nav ul li {
        padding: 0px 5px;
    }
    .header .navbar-nav ul li a {
        font-size: 0.75rem;
    }
    .headerButton li {
        padding: 0px 4px;
    }
  }
  @media screen and (max-width:991px){
    .headerMenu  {
        background: #fff;
        padding: 10px;
    }
    .header .navbar-nav ul li {
        width: 100%;
        padding: 5px 0px;
    }
    .headerButton {
        flex-wrap: wrap;
    }
    .headerButton li {
        width: 50%;
        display: flex;
        padding: 10px 15px;
    }
    .header .navbar-nav ul{
        padding: 0px 15px;
    }
    .btnOutline {
        padding: 12px 0px;
        font-size: 1rem;
        width: 100%;
        text-align: center;
    }
    .btnfilled {
        padding: 12px 0px;
        font-size: 1rem;
        width: 100%;
        text-align: center;
    }
    .header .navbar-nav ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header .navbar-nav .dropdown-menu {
        position: absolute;
        left: 0px;
        width: 100%;
    }
    
  }
  @media screen and (max-width:768px){
    
  }
  @media screen and (max-width:767px){
    
  }
  
  @media screen and (max-width:576px){
    .headerButton li {
        width: 100%;
        display: flex;
        padding: 10px 0px;
    }
    .header .navbar-nav ul{
        padding: 0px;
    }
  }
  @media screen and (max-width:420px){
    
  }