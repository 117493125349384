.documentsSection h6.h6 {
    line-height: 150%;
    margin-bottom: 13px;
}
.documentsSection ul li {
    padding: 8px 0px;
    color:var(--text-color);
    font-family: var(--primary-font);
    font-size: 1rem;
    font-weight: 500;
    line-height: 180%;
}
.documentsSection ul li img{
    margin-right: 15px;
}
.journeySection {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
    padding: 30px;
}
.journeySection h3 {
    margin-bottom: 20px;
    font-weight: 600;
    line-height: 180%;
}
.FormEligibility .Continue {
    width: 100%;
    background: var(--secondary-color);
    font-size: 18px;
    font-weight: 600;
}
/* .journeySection .input-group.labelhide label {
    display: none;
} */
.FormEligibility .input-group{
    margin-bottom: 0px !important;
    padding-bottom: 30px;
}
