.usp--inner {
    justify-content: center;
}
.usp--inner .h2 {
    font-weight: 600;
    line-height: 150%;
}
.upsId {
    width: 100%;
    margin: 0px 0px 40px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upsId h4.h4 {
    font-weight: 600;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    justify-content: center;
    display: inline-flex;
    border: 1px solid var(--text-color);
    margin: 0px;
    align-items: center;
    position: relative;
    z-index: 99;
    background: var(--white);
}
.uspCard {
    position: relative;
    justify-content: center;
}

.uspCard::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 74%;
    background: var(--text-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 26px;
}
.upsInner .h5 {
    font-weight: 600;
    margin-bottom: 20px;
}
.uspHome .upsInner {
    padding: 0px;
}
.upsInner p {
    position: relative;
    display: inline-flex;
    justify-content: center;
    padding-bottom: 25px;
    margin-bottom: 0px;
}
.upsInner p::before {
    position: absolute;
    content: "";
    bottom: 0px;
    width: 10%;
    background: var(--text-color);
    height: 1px;
}

@media screen and (max-width:1399px){
    
   
}
@media screen and (max-width:1199px){
    
}
@media screen and (max-width:991px){
    .uspHome .upsCards {
        width: 50%;
    }
    .uspCard::before{
        display: none;
    }
}
    
@media screen and (max-width: 768px) {
    
}
@media screen and (max-width:767px){
    .uspHome .upsCards {
        width: 100%;
        margin-top: 40px;
    }
    .upsId{
        margin-bottom: 10px;
    }
}

@media screen and (max-width:576px){

}
@media screen and (max-width:420px){

}
  

