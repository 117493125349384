@import url('https://fonts.googleapis.com/css2?family=Cantora+One&family=Poppins:wght@400;500;600;700;800;900&display=swap');

:root {
  --primary-color: #009B35;
  --black: #000;
  --white: #fff;
  --secondary-color: #EF8200;
  --text-color: #2a2a2a;
  --bg-light: #E1FFF5;
  --top-bg: #FFF5F2;
  --border-color: #D3D3D3;
  --footer-bg: #131313;
  --faq: #091242;
  --worksBg: #BCDFEB;
  --bg-gradient: linear-gradient(90deg, #FCAF1B -1.02%, #EC5E1C 100%);
  /*--Fonts-var*/
  --primary-font: 'Poppins', sans-serif;
  --secondary-font: 'Cantora One', sans-serif;
  /*--Fonts-Size*/
  --h1: 3.1rem;
  --h2: 2.5rem;
  --h3: 1.9rem;
  --h4: 1.5rem;
  --h5: 1.25rem;
  --h6: 1.1rem;
  --body-size: 0.9rem;
  --main-font: 1rem;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--primary-font);
  font-size: var(--body-size);
  line-height: 180%;
  color: var(--text-color);

}

body a {
  font-family: var(--primary-font);
  font-size: var(--body-size);
  line-height: 180%;
  color: var(--text-color);
  text-decoration: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

input,
textarea,
select {
  font-family: var(--primary-font);
  font-size: var(--body-size);
  line-height: 180%;
  color: var(--text-color)
}

img,
a img {
  border: none;
  outline: none;
  max-width: 100%;
  display: block;
  height: auto;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font);
}

body h1,
body .h1 {
  font-size: var(--h1);
}

body h2,
body .h2 {
  font-size: var(--h2);
  font-weight: 600;
  line-height: 150%;
}

body h3,
body .h3 {
  font-size: var(--h3);
  color: var(--text-color);
}

body h4,
body .h4 {
  font-size: var(--h4);
  color: var(--text-color);
}

body h5,
body .h5 {
  font-size: var(--h5);
  color: var(--text-color);
}

body h6,
body .h6 {
  font-size: var(--h6);
  color: var(--text-color);
}

p {
  font-family: var(--primary-font);
  font-size: var(--body-size);
  line-height: 180%;
  color: var(--text-color);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.text-color {
  color: var(--text-color);
}

.bg-light {
  background: var(--bg-light);
}

.bg-gradient {
  background: var(--bg-gradient);
}

.btnOutline {
  border: 1px solid var(--secondary-color);
  background-color: transparent;
  padding: 12px 20px;
  border-radius: 5px;
  color: var(--secondary-color);
  font-size: var(--body-size);
  font-weight: 500;
  line-height: 150%;
}

.btnfilled {
  background-color: var(--primary-color);
  padding: 12px 20px;
  border-radius: 5px;
  color: var(--white);
  font-size: var(--body-size);
  font-weight: 500;
  cursor: pointer;
  line-height: 150%;
  font-family: var(--primary-font);
}

.btn-custom:hover {
  background-color: var(--secondary-color);
}

.colorChnage {
  background-color: var(--secondary-color);
}

.colorChnage:hover {
  background-color: var(--primary-color);
}

.sectionPadding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.sectionTop {
  padding-top: 80px;
}

.sectionBottom {
  padding-bottom: 80px;
}

.gray-border {
  color: #2D2D2D;
}

/* .innerBanner{
  background-image: url(./assets/img/about-banner.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
} */
.innerBanner::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: linear-gradient(90deg, rgba(13, 13, 13, 0.74) 0%, rgba(13, 13, 13, 0.00) 100%);
  width: 100%;
  height: 100%;
  z-index: 9;
}

.bannerContent {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.bannerContent h1 {
  color: var(--white);
  font-weight: 700;
  line-height: 150%;
}

.breadcrumbs a,
.breadcrumbs span {
  color: var(--white);
  text-transform: capitalize;
  line-height: 180%;
  font-size: var(--body-size);
  font-family: var(--primary-font);
  padding: 0px 0px 0px 10px;
}

.breadcrumbs a {
  padding-right: 10px;
  padding-left: 0px;
}

.homeServices {
  background: var(--bg-light);
}

.homeServices .row:nth-child(2n) {
  flex-direction: row-reverse;
}

body select,
body input,
body textarea {
  line-height: 150%;
  margin: 0px 0px;
  font-size: 1rem;
  font-family: var(--primary-font);
  font-weight: 500;
  height: 50px;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  border-radius: 5px !important;
  width: 100%;

}

body .custom-button,
body .submit {
  background-color: var(--primary-color);
  padding: 12px 20px;
  border-radius: 5px !important;
  color: var(--white);
  font-size: var(--body-size);
  font-weight: 500;
  line-height: 150%;
  border: none;
  font-family: var(--primary-font);
  height: 50px;
}

.newsLetter input {
  margin-right: 20px;
  min-width: 350px;
  max-width: 400px;

}

.eligibilityForm select {
  margin-right: 20px;
  min-width: 350px;
  max-width: 400px;
  border: none
}

ul.socialIcons li:first-child {
  padding-left: 0px;
}

.breadcrumbs span:first-child {
  padding-left: 0px !important;
}

.succesMessage {
  color: green;
  text-transform: capitalize;
  font-size: 1rem;
}

.errormessage {
  color: red;
  text-transform: capitalize;
  font-size: 13px;
  margin: 0px;
  position: absolute;
  bottom: 6px;
}

.errormessageApply {
  color: red;
  text-transform: capitalize;
  font-size: 13px;
  margin: 0px;
  width: 100%;
}

.successMessageApply {
  color: green;
  text-transform: capitalize;
  font-size: 13px;
  margin: 0px;
  width: 100%;
}

.successMessage {
  color: green;
  text-transform: capitalize;
  font-size: 13px;
  margin: 0px;
  position: absolute;
  bottom: 6px;
  width: 100%;
}

.errorClass {
  position: relative;
}

.ReactModal__Content.ReactModal__Content--after-open {
  max-width: 600px;
  margin: 0px auto;
  padding: 0px !important;
}

.modalHeader {
  display: flex;
  padding: 30px;
  border-bottom: 1px solid var(--border-color);
  justify-content: space-between;
  align-items: center;
}

.modalHeader button {
  background: none;
  border: none;
}

/* START -> header login button css */

/* Base Styles */
.headerButton {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0;
  margin: 0;
}

.headerButton li {
  margin: 0;
}

/* Dropdown Container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Button Styles */
.dropdown button {
  background-color: #EF8200;
  /* Primary color */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Dropdown Menu */
.menu {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.menu ul li a {
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
}

.menu ul li:hover {
  background-color: #f8f9fa;
}

.menu ul li:hover a {
  color: #EF8200;
}

.menu ul li a i {
  margin-right: 6px;
  font-size: 16px;
}

/* Media Query for Responsiveness */
@media (max-width: 768px) {
  .headerButton {
    flex-direction: column;
    gap: 10px;
  }

  .menu {
    left: auto;
    right: 0;
  }
}


/* END -> header login button css */

.modalBody {
  padding: 30px;
  width: 100%;
}

.modalBody img {
  border-radius: 5px;
  width: 100%;
}

input:focus {
  outline: none;
  box-shadow: none;
}

.appointmentSction .react-datepicker-wrapper {
  width: 100%;
}

.appointmentSction .datepicker {
  width: 100%;
  display: flex;
  align-items: center;
}

.appointmentSction .datepicker .input-group-prepend {
  position: absolute;
  right: 0px;
  /* z-index: 99; */
  padding: 15px;
}

.appointmentSction .react-datepicker button {
  min-width: inherit !important;
}

.appointmentSction .datepicker .input-group-prepend svg {
  color: #666;
}

.appointmentSction .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.appointmentSction .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  right: 0px !important;
  left: auto !important
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: transparent;
}

.react-datepicker__input-container input:focus {
  color: var(--bs-body-color);
  background-color: transparent !important;
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, .25) !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  width: 49%;
}

.react-datepicker__year-select-container {
  margin-right: 2%;
}

.opportunitiesInner {
  background: var(--secondary-color);
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
}

.opportunitiesInner h2.h2 {
  color: var(--white);
  font-weight: 400;
  /* font-size: 2.2rem; */
  margin-bottom: 20px;
}

.opportunitiesInner .btnemail {
  background: var(--primary-color);
  padding: 10px 40px;
  display: inline-flex;
  justify-content: center;
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--white);
  border-radius: 5px;
  min-width: 200px;
  line-height: 200%;

}

.opportunitiesInner .btnemail:hover {
  background: var(--text-color);
}

.OpportunitiesList {
  border-bottom: 1px solid var(--border-color);
  padding-top: 15px;
}

.jobsList {
  border-top: 1px solid var(--border-color);
  padding: 30px 0px;
}

.jobsList p {
  margin: 0px;
}

.jobsList h4.h4 {
  font-weight: 600;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  animation: marquee 15s linear infinite;
  width: 100%;
  display: flex;
}

.marquee-container.paused .marquee-content {
  animation-play-state: paused;
}

.marqueePosition {
  position: relative;
  width: 100%;
  background: var(--primary-color);
}

.marqueePosition h2.h2 {
  font-size: 1.5rem;
  margin: 0px;
  padding: 10px 0px;
  color: var(--white);
  letter-spacing: 2px;
  font-weight: 500;
}

.marquee-container.paused .marquee-content {
  animation-play-state: paused;
}

.mainLoader {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  overflow: hidden;
}

.contactFormDeatils .input-group .radioLabel label {
  display: none;
}

.responsibilities {
  margin-top: 20px;
}

.responsibilities ul {
  margin: 0px;
  padding-left: 20px;
  list-style: outside;
}

.responsibilities ul li {
  line-height: 180%;
  font-family: var(--primary-font);
  font-size: var(--body-size);
}

.ReactModalPortal {
  z-index: 999;
  position: relative;
}

.modalSuccess {
  background: var(--white);
  margin-top: 40px !important;
  border-radius: 5px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.20);
}

.modalSuccess .modalBody p {
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 500;
}

.modalSuccess .buttonClose {
  color: var(--white);
  font-size: 1.1rem;
  font-weight: 500;
  border: none;
  background: var(--secondary-color);
  padding: 7px 25px;
  line-height: 180%;
  font-family: var(--primary-font);
  border-radius: 5px;
  min-width: 120px;
}

.submit.fade {
  opacity: 0.5 !important;
}

.applyNow .input-group input.feild_disabled {
  background: #f5f5f5;
  opacity: 0.6;
  pointer-events: none;
}

.applyNow .input-group.submitButton {
  padding: 0px !important;
}

.errorpage {
  background-color: #f5f5f5;
}

.errorpage figure {
  justify-content: center;
  display: flex;
}

.errorpage .next-error-h1 {
  font-weight: 700;
  font-size: 4rem;
  color: var(--secondary-color);
}

.footer a {
  color: var(--white);
}

.footer a:hover {
  color: var(--secondary-color);

}

body.ReactModal__Body--open {
  overflow: hidden;
}

.sectionPolicy a {
  color: var(--primary-color);
}

.sectionPolicy a:hover {
  color: var(--secondary-color);
}

.input-group.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.input-group.active {
  opacity: 1;
  pointer-events: visible
}

.firstName {
  text-transform: uppercase;
}

.applyDetails .input-group.submitButton {
  font-family: var(--primary-font);
  color: red;
  font-size: 16px;
  font-weight: 600;
}


@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width:1399px) {

  body h1,
  body .h1 {
    font-size: 2.5rem;
  }

  body h2,
  body .h2 {
    font-size: 2rem;
  }

  body h3,
  body .h3 {
    font-size: 1.5rem;
  }

  body h4,
  body .h4 {
    font-size: 1.25rem;
  }

  body h5,
  body .h5 {
    font-size: 1.2rem;
  }

  body h6,
  body .h6 {
    font-size: 1rem;
  }
}

@media screen and (max-width:1199px) {

  body h1,
  body .h1 {
    font-size: 2.2rem;
  }

  body h2,
  body .h2 {
    font-size: 1.8rem;
  }

  body h3,
  body .h3 {
    font-size: 1.3rem;
  }

  body h4,
  body .h4 {
    font-size: 1.2rem;
  }

  body h5,
  body .h5 {
    font-size: 1rem;
  }

  .sectionPadding {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .sectionTop {
    padding-top: 70px;
  }

  .sectionBottom {
    padding-bottom: 70px;
  }
}

@media screen and (max-width:991px) {

  body h1,
  body .h1 {
    font-size: 1.8rem;
  }

  body h2,
  body .h2 {
    font-size: 1.4rem;
  }

  body h3,
  body .h3 {
    font-size: 1.2rem;
  }

  body h4,
  body .h4 {
    font-size: 1.1rem;
  }

  .innerBanner img {
    height: 250px;
    object-fit: cover;
  }

  .loanDetailsSection .productsCards {
    width: 50%;
    flex: auto;
    margin: 15px 0px;
  }
}

@media screen and (max-width:768px) {}

@media screen and (max-width:767px) {

  body h1,
  body .h1 {
    font-size: 1.5rem;
  }

  .sectionPadding {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .sectionTop {
    padding-top: 50px;
  }

  .sectionBottom {
    padding-bottom: 50px;
  }

  .innerBanner img {
    height: 200px;
    object-fit: cover;
  }

  .workCards p {
    margin: 0px;
  }

  .sectionBottom .workCards:nth-child(2) {
    margin-bottom: 0px;
  }

  .loanDetailsSection .productsCards {
    width: 100%;
  }

  .calulator--chart.row {

    padding: 20px !important;
  }

  .input-group label {
    flex-wrap: wrap;
  }

  .calulator--chart .col-12 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .calculatorArea .input-group .feild {
    width: 100%;
    min-width: 100% !important;
  }

  .totalAmount p {
    font-size: 1.1rem !important;

  }
}

@media screen and (max-width:576px) {

  body h1,
  body .h1 {
    font-size: 1.25rem;
  }

  .innerBanner img {
    height: 180px;
    object-fit: cover;
  }
}

@media screen and (max-width:420px) {
  .innerBanner img {
    height: 150px;
    object-fit: cover;
  }
}