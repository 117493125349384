.financialCards .financialInner {
    padding: 0px;
    border: navajowhite;
}
.financialInner  figure {
    width: 100%;
    justify-content: center;
    display: inline-flex;
}
.financialInner h6 {
    color: var(--text-color);
}
.buttonCards {
    justify-content: center;
    display: inline-flex;
}


.homeAbout{
    background-color: var(--bg-light);
}
.imageReverse{
    flex-direction: row-reverse;
}

.AboutTop{
    align-items: center;
}
.AboutTop .h2 {
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 140%;
}
.about--list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
}
.about--list  li{
    display: flex;
    width: 50%;
    padding-bottom: 20px;
    align-items: center;
}
.about--list  li img{
    margin-right: 15px;
}
.about--list li h6 {
    font-weight: 600;
    line-height: 160%;
    margin: 0px;
    font-size: 1rem;
}
.input-group{
    margin-bottom: 20px;
}
.instantLoanForm{
    max-width: 60%;
}
.homeAbout .input-group input{
    width: 100%;
    height: 50px;
    padding: 0px 15px;
    font-size: var(--body-size);
    border: 1px solid var(--border-color);
    background-color: transparent;
    color: var(--text-color);
    border-radius: 3px;
    font-family: var(--primary-font);

}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}
.input-group.submit .btnfilled {
    width: 100%;
    border: none;
    background: var(--secondary-color);
    font-family: var(--primary-font);
    font-size:var(--main-font);
    color: var(--white);
}
.input-group.submit .btnfilled:hover{
    background-color: var(--primary-color);
}
.InstantSection{
    align-items: center;
}

.InstantSection .h2 {
    line-height: 150%;
    font-weight: 600;
    max-width: 60%;
    margin-bottom: 20px;
}
.InstantSection strong {
    font-weight: 600;
    font-size: large;
}
.InstantSection strong {
    font-weight: 600;
    font-size: var(--body-size);
    line-height: 180%;
}
.InstantSection strong a{
    color: var(--primary-color);
}
.newsLetter .input-group {
    display: flex;
    justify-content: space-between;
}
.newsLetter .input-group input {
    padding: 10px 20px;
    background: var(--white);
    border-radius: 3px !important;
    border: none;
    height: 50px;
    max-width: 70%;
    width: 100%;
    margin-right: 15px;
    font-family: var(--primary-font);
    font-size: 1.05rem;
}
.newsLetter .input-group .submit {
    border: none;
    border-radius: 3px !important;
    max-width: 25%;
    height: 50px;
    color: var(--white);
    background: var(--primary-color);
    font-family: var(--primary-font);
    font-size: 1.05rem;
    font-weight: 500;
    padding: 0px 30px;
}

@media screen and (max-width:1399px){
    
}
@media screen and (max-width:1199px){
    .InstantSection .h2 {
        max-width: 100%;
        margin-bottom: 10px;
    }
    .instantLoanForm {
        max-width: 90%;
    }
    .AboutTop .h2 {
        margin-bottom: 10px;
    }
    .about--list li h6 { 
        line-height: 150%;
    }
    .about--list li {
        width: 50%;
        padding-bottom: 10px;
    }
}
@media screen and (max-width:991px){
    .instantLoanForm {
        max-width: 100%;
    }
    .homeAbout .col-lg-12 {
        margin-bottom: 10px;
    }

}
@media screen and (max-width:768px){

}
@media screen and (max-width:767px){
    .about--list li {
        width: 100%;
        padding-bottom: 10px;
    }

}

@media screen and (max-width:576px){

}
@media screen and (max-width:420px){

}