.applyNow .input-group {
    margin-bottom: 0px;
    padding-bottom: 30px;
}
.applyNow .input-group.first{
    width: 49%;
    /* padding-right: 15px; */
} 
.applyNow .input-group.last{
    /* padding-left: 15px; */
    width: 49%;
}
form.applyNow .loanDetails{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    
}
.applyNow label {
    font-family: var(--primary-font);
    line-height: 180%;
    padding-bottom: 10px;
    color: var(--text-color);
}
.applyNow .input-group.radioButton label.radioLabel label {
    display: none;
}
.applyDetails {
    width: 100%;
}
.applyNow .input-group button{
    min-width: 150px
}
.imagePrev img {
    width: 40%;
}
.applyNow .input-group.buttonValidate button {
    margin-top: 15px;
}
.applyNow .input-group.radioButton label.mainLabel {
    padding-left: 0px;
    padding-bottom: 10px;
}
.applyNow .input-group.radioButton label.radioLabel {
    line-height: 50px;
}
.withicon img.check-icon {
    position: absolute;
    bottom: 13px;
    right: 10px;
    width: 24px;
}
.ModalTerms .modal-dialog {
    max-width: 1000px;
}
.ModalTerms .modalHeader {
    padding: 15px;
    display: flex;
    align-items: center;
}
.ModalTerms .modalHeader h2{
   margin: 0px;
}
@media screen and (max-width:767px){
    .applyNow .input-group.first, .applyNow .input-group.last {
        width: 100%;
        
    }
   
}