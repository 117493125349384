.srvicesSection .row:nth-child(2n) {
    flex-direction: row-reverse;
}

.srvicesSection h2 {
    margin-bottom: 0px;
}

.servicesList {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    padding-bottom: 20px;
}

.servicesList li {
    display: flex;
    width: 50%;
    padding: 10px 0px;
    align-items: center;
    font-family: var(--primary-font);
    line-height: 180%;
    font-weight: 500;
    color: var(--text-color);
}

.servicesList li img {
    margin-right: 10px;
}

.loanDetailsSection .cardsInner {
    text-align: center;
    padding: 0px;
}

.loanDetailsSection .productsTitle {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.loanDetailsSection .productsTitle .h5 {
    width: 100%;
}

.loanDetailsSection .productsTitle img.cardIcon {
    margin-bottom: 15px;
}

.calulator--chart.row {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    padding: 40px;
}

.calculatorArea label {
    justify-content: space-between;
    display: flex;
    width: 100%;
    font-size: var(--h6);
    font-weight: 600;
    font-family: var(--primary-font);
    align-items: center;
    margin-bottom: 20px;
}

.calculatorArea label span {
    padding: 5px 20px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.calculatorArea .input-group input {
    width: 100%;
    padding: 0px;
    height: auto;
    margin: 0px;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border: none;
    overflow: hidden;
    border-radius: 20px !important;

}

.calculatorArea .input-group {
    margin-bottom: 0px;
    padding-bottom: 30px;
}

.calculatorArea .input-group input[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: #D0D0D0;
    border-radius: 20px;
}

.calculatorArea .input-group input[type="range"]::-moz-range-track {
    height: 15px;
    background: #D0D0D0;
    border-radius: 20px;
}

.calculatorArea .input-group input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: var(--text-color);
    border-radius: 50%;
    box-shadow: -407px 0 0 400px var(--secondary-color);
}


.buttonApply .btnfilled {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--white);
    font-family: var(--primary-font);
    font-size: var(--h6);
    font-weight: 600;
    line-height: 150%;
}

.calculatorArea .input-group input[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: var(--text-color);
    border-radius: 50%;
    box-shadow: -407px 0 0 400px var(--secondary-color);
}

.feild {
    display: flex;
    position: relative;
}

.calculatorArea .input-group .feild input {
    width: 100%;
    border: none;
    border-radius: 3px !important;
    padding: 0px 5px;
    margin: 0px;
    height: 36px;
    appearance: auto !important;
}

.calculatorArea .input-group .feild {
    border: 1px solid var(--border-color);
    min-width: 100px;
    max-width: 150px;
    display: inline-flex;
    align-items: center;
    padding: 0px 15px;
    border-radius: 5px;
}

.calculatorArea .input-group .feild p {
    margin: 0px;
    font-family: var(--primary-font);
    line-height: 180%;
    color: var(--text-color);
    font-size: 1rem;
}

.infoCalculator {
    padding: 20px 0px 0px;
}

.infoCalculator p {
    padding: 0px;
    font-family: var(--primary-font);
    line-height: 180%;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 400;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.infoCalculator p span {
    font-weight: 600;
    font-size: 1.1rem;
}
.totalAmount p {
    justify-content: space-between;
    display: flex;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-color);
    padding: 0px;
}
.totalAmount p span{
    color: var(--primary-color);
}
.pieChart {
    display: flex;
    justify-content: center;
}

@media screen and (max-width:991px) {
    .servicesList li {
        display: flex;
        width: 100%;
        padding: 3px 0px;
    }
}

@media screen and (max-width:767px) {
    .srvicesSection h2 {
        padding-top: 20px;
    }

    .servicesList {
        padding-bottom: 0px;
    }

    .servicesList li {
        padding: 5px 0px;
    }

}