.appointmentSction .input-group {
    margin-bottom: 0px;
    padding-bottom: 30px;
}
.appointmentSction .input-group.first{
    width: 49%;
    /* padding-right: 15px; */
} 
.appointmentSction .input-group.last{
    /* padding-left: 15px; */
    width: 49%;
}
form.appointmentForm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
}
.input-group label {
    font-family: var(--primary-font);
    line-height: 180%;
    padding-bottom: 10px;
    color: var(--text-color);
}
 .input-group.checkbox label {
    display: flex;
    width: 100%;
    text-align: left;
    
    padding: 0px ;
}
.input-group.radioButton input[type="radio"] {
    height: 20px;
    position: absolute;
    left: 0px;
   
    width:20px
}
.contactFormDeatils .input-group .radioLabel {
    width: 50%;
}
.input-group.radioButton label {
    display: flex;
    width: 100%;
    text-align: left;
    
    padding: 0px 0px 0px 30px;
}
.input-group.checkbox input[type="checkbox"] {
    width: 24px;
    height: 24px;
    margin-right: 15px;
}

 .input-group.checkbox span a {
    color: var(--primary-color);
}
.input-group.checkbox span {
    font-size: 1rem;
}
 .input-group button{
    min-width: 150px
}
.appointmentSction .input-group button.reset {
    background: none;
    padding: 12px 20px;
    border-radius: 5px !important;
    color: var(--secondary-color);
    font-size: var(--body-size);
    font-weight: 500;
    line-height: 150%;
    border: none;
    font-family: var(--primary-font);
    height: 50px;
    border: 1px solid var(--secondary-color);
}
.appointmentSction .input-group button:hover{
    background:var(--secondary-color);
    color: var(--white);
}
@media screen and (max-width:767px){
    .appointmentSction .input-group.first, .appointmentSction .input-group.last {
        width: 100%;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .appointmentSction .input-group button {
        min-width: 120px;
    }
   
}