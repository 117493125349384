.imageSection {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.queryCall {
    background: var(--faq);
    max-width: 400px;
    position: absolute;
    left: 0px;
    padding: 40px 50px;
}
.queryCall h5 {
    color: var(--white);
    font-weight: 500;
    line-height: 180%;
    margin-bottom: 20px;
}
.queryCall img.imageIcon {
    margin-right: 15px;
}
.queryCall p {
    margin-bottom: 0;
    color: var(--white);
    line-height: 180%
}
.faqSection .accordion-item {
    border: none;
    margin-bottom: 40px;
}
.faqSection .accordion-main {
    margin-top: 40px;
    
}
.faqSection .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.accordion-header h5 {
    margin: 0px;
    line-height: 180%;
}
@media screen and (max-width:767px){
    .queryCall {
        max-width: 100%;
        position: relative;
        padding: 30px ;
    }
    .imageSection {
        position: relative;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}