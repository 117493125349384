.Eligibility{
    position: relative;

}
.Eligibility:before{
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
}
.bannerContent h2{
    color: var(--white);
}

.eligibilityForm {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.productTop{
    text-align: center;
}
.productTop .h2 {
    font-weight: 600;
    line-height: 160%;
    margin: 0px;
}
.productsSection  .cardsInner {
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    background: var(--white);
    padding: 20px;
}
.productsSection  .productsTitle {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    min-height: 60px;
}
.productsTitle .h5 {
    font-weight: 600;
    line-height: 150%;
    margin: 0px;
}
.productsSection  .cardsInner p {
    margin-bottom: 10px;
}
.productsSection .productsTitle .cardIcon {
    margin-right: 15px;
}
.eligibilityForm label {
    display: none;
}
.homeServices figure {
    margin: 0px;
}
.eligibilityArea p.error-message {
    color: red;
}
@media screen and (max-width:1399px){
    .productsTitle .h5{
        font-size: 1rem;
    }
    
    .about--list li h6 {
        font-size: 1rem;
    }
    .servicesList li {
        font-size: 0.8rem;
    }
}
@media screen and (max-width:1199px){
    .newsLetter .input-group input {
        max-width: 100%;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
    } 
    
}
@media screen and (max-width:991px){
    
    .productsSection .productsCards {
        width: 50%;
        margin-bottom: 20px;
        flex: auto;
    }
    .homeServices figure {
        margin-bottom: 20px;
    }
}
@media screen and (max-width:768px){

}
@media screen and (max-width:767px){
    .productsSection .productsCards {
        width: 100%;
        margin-bottom: 20px;
    }
    .eligibilityForm select {
        margin-right: 0px;
        max-width: 100%;
        min-width: 100%;
    }
    .eligibilityForm button.custom-button {
        width: 100%;
        margin-top: 20px;
    }
    .uspHome .upsCards {
        margin-top: 20px;
    }
    .Eligibility img {
        height: 250px;
        object-fit: cover;
    }
}
    
@media screen and (max-width:576px){
    
}
@media screen and (max-width:420px){
    .Eligibility  .bannerContent h2 {
        font-size: 1.1rem;
    } 
}