.aboutInfo .h5 {
    font-weight: 300;
    line-height: 180%;
}
.mission--inner {
    align-items: flex-start;
    margin-bottom: 20px;
}
.mission--inner p{
    margin-bottom: 0px;
}
.mission--inner .missionImage{
    margin-right: 15px;
}
.aboutTeam .teamCards {
    margin: 15px 0px;
    background-color: transparent;
    cursor: pointer;
}
.aboutTeam .teamInner {
    padding: 0px;
    background: var(--white);
}
.aboutTeam {
    background: var(--bg-light);
}
.teamDescription {
    padding: 20px;
}
.teamDescription p {
    margin: 0px;
    color: #7D7D7D;
    line-height: 180%;
}
.teamDescription h5 {
   font-weight: 600;
   margin: 0px ;
    line-height: 180%;
}