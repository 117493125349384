.howItWorksHome{
    background: var(--bg-gradient);
}
.howItWorksHome .h2{
    color: var(--white);
}
.work--inner .workCards {
    background: transparent;
    text-align: center;
}
.workInner {
    text-align: center;
    justify-content: center;
    display: inline-flex;
    flex-wrap: wrap;
}
.workInner .h5 {
    margin-top: 25px;
    color: var(--white);
    display: flex;
    align-items: flex-start;
    padding: 0px 20px;
    line-height: 160%;
    width: 100%;
    text-align: center;
    justify-content: center;
}
.workCards::before {
    position: absolute;
    content: "";
    left: -69px;
    align-items: center;
    display: flex;
    background-image: url(./../../../../assets/img/arrowW.svg);
    width: 152px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center right;
    top: 60px;
}
.workCards.col:first-child:before{
    display: none;
}
.howItWorksHome .workInner img.cardIcon {
    width: auto;
    height: 90px;
   
}
.howItWorkspage .workCards::before {
    left: -61px;
    width: 100px;
    height: 30px;
    top: 46px;
    background-size: cover;
}
.howItWorkspage .workCards h5 {
    font-size: 1rem;
}
@media screen and (max-width:1399px){
    .workInner .h5 {
        padding: 0px;
    }
}
@media screen and (max-width:1199px){
    .workCards::before {
        left: -100px;
        background-size: 65% auto;
    }
}
@media screen and (max-width:991px){
    .work--inner .workCards {
        width: 50%;
        flex: auto;
    }
    .workCards::before{
        display: none;
    }
    .workInner .h5 {
        padding: 0px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .calculator  .col-md-7, .calculator  .col-md-5{
        width: 100%;
        margin-top: 20px;
    }
}
@media screen and (max-width:768px){

}
@media screen and (max-width:767px){
    .work--inner .workCards {
        width: 100%;
    }

}

@media screen and (max-width:576px){

}
@media screen and (max-width:420px){

}