.footer{
    background: var(--footer-bg);
    padding: 50px 0px 0px;
}
.footerTop .cardsInner .h5 {
    display: block;
    width: 100%;
}
.footerTop  .productsTitle {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
}
.footerTop .cardsInner {
    border-color: var(--border-color);
    border-radius: 3px;
    padding: 40px;
    text-align: center;
}
.footerTop .cardsInner .productsTitle img.cardIcon {
    margin: 0px 0px 20px;
}

.footerTop .cardsInner .h5 {
    display: block;
    width: 100%;
    line-height: 180%;
}
.newsLetter .h4{
    color: var(--white);
    line-height: 140%;
    margin: 0px;
}
.newsLetter p {
    margin: 0px;
}
.newsLetter .input-group {
    display: flex;
}
.whatsApp {
    display: flex;
    align-items: flex-start;
}

.whatsApp img {
    margin-right: 20px;
}
.whatsApp .h4 {
    color: var(--white);
    line-height: 200%;
    font-weight: 600;
}
.footerInfo p {
    color: var(--white);
    margin: 20px 0px;
}
.info {
    display: flex;
    align-items:flex-start;
    padding: 10px 0px;
}
.info img{
    margin-right: 15px;
    margin-top: 3px;
}
ul.socialIcons {
    margin: 0px;
    display: flex;
    padding: 20px 0px;
}
ul.socialIcons li {
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}
ul.socialIcons li a {
    width: 24px;
    height: 24px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
}
.footerLinks h4.h4 {
    color: var(--white);
    line-height: 180%;
    font-size: 1.25rem;
}
.footerLinks ul {
    margin: 0px;
    padding: 0px;
}
.footerLinks ul li a{
    color: var(--white);
    line-height: 180%;
    font-size: 0.8rem;
}
.footerLinks ul li {
    padding: 5px 0px;
}
.newsLetter form.mt-3 {
    max-width: 550px;
}
@media screen and (max-width:1399px){
    .newsLetter p {
        margin: 0px;
        font-size: 13px;
    }
}
@media screen and (max-width:1199px){
    .info strong {
        display: block;
    }
}
@media screen and (max-width:991px){
    .footerNewsLetter .col-md-7, .footerNewsLetter .col-md-5, .footer .col-md-4, .footer .col-md-8 {
        width: 100%;
        padding: 15px 0px;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .footer .col-md-8 .col, .footer .col-md-8 .col-md-4{
        flex: auto;
        width: 50%;
        padding-left: 15px !important;
    }
    
}
@media screen and (max-width: 768px) {
    
}
@media screen and (max-width:767px){
    .footer .col-md-8 .col, .footer .col-md-8 .col-md-4{
        flex: auto;
        width: 100%;
        padding-left: 15px !important;
    }
    
}

@media screen and (max-width:576px){

}
@media screen and (max-width:420px){

}